import React from "react";
import translate from "redux-polyglot/translate";
import {
    Button,
    Form,
    Input
} from "antd";
import {CloseOutlined, SendOutlined} from "@ant-design/icons";
import Draggable from "react-draggable";
import ChatMessagesList from "./ChatMessagesList";

const InputGroup = Input.Group;
const {Item} = Form;

export default class VideoCallChat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            help: null,
            validateStatus: null,
            value: null
        };

        this.nodeRef = React.createRef(null);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handles the state of the component when a new message is sent.
     */
    handleSubmit() {
        if (this.state.value && this.props.participants.length) {
            this.props.onNewMessage(
                Array.from(this.props.participants).map(participant => participant.identity),
                this.state.value
            );
            this.setState({value: null});
        }
    }

    render() {
        return (
            <Draggable nodeRef={this.nodeRef} bounds={"body"} cancel={`${'ontouchstart' in document.documentElement ? "#chat-container" : ""}`} >
                <div id="chat-container" ref={this.nodeRef}>
                    <div id="chat-header" style={{backgroundColor: "#fff", padding: "1rem"}}>
                        <span>Chat</span>
                        <span className="inspector-close" style={{display: "flex"}}>
                        <CloseOutlined onClick={this.props.onClose}/>
                    </span>
                    </div>
                    <div id="chat-body">
                        <ChatMessagesList chat={this.props.chat} identity={this.props.participant.identity} noParticipants={this.props.participants.length === 0}/>
                    </div>
                    <div style={{padding: "0.5rem 1rem", backgroundColor: "#fff", width: "100%"}}>
                        <Form id="chat-form" onFinish={this.handleSubmit}>
                            <InputGroup compact>
                                <Item help={this.state.help}
                                      validateStatus={this.state.validateStatus}>
                                    <Input type="text"
                                           placeholder={this.props.p.t("inspection.message")}
                                           style={{
                                               textAlign: "left",
                                               borderTopLeftRadius: "8px",
                                               borderBottomLeftRadius: "8px"
                                           }}
                                           value={this.state.value}
                                           onChange={event => this.setState({value: event.target.value})}/>
                                </Item>
                                <Button type="primary"
                                        htmlType="submit"
                                        style={{borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}
                                        disabled={this.props.participants.length === 0 || (!this.state.value || !this.state.value.trim())}
                                        icon={<SendOutlined style={{position: "relative", bottom: ".25em"}}/>}>
                                    {this.props.p.t("inspection.send")}
                                </Button>
                            </InputGroup>
                        </Form>
                    </div>
                </div>
            </Draggable>
        );
    }
}

VideoCallChat = translate(VideoCallChat);