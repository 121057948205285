import React from "react";
import translate from "redux-polyglot/translate";
import {Input} from "antd";

export default class UserIdentityInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            input: "",
            validateStatus: null
        };

        this.handleTextChange = this.handleTextChange.bind(this);
    }

    /**
     * Handles the state of the component when the content of the text input changes.
     */
    handleTextChange(newIdentity) {
        if (newIdentity && newIdentity.trim()) {
            this.setState({input: newIdentity, validateStatus: null});
            this.props.setIdentity(newIdentity);
        } else {
            this.setState({input: newIdentity, validateStatus: "error"});
            this.props.setIdentity(newIdentity);
        }
    }

    render() {
        return (
            <div style={{margin: "1rem 0"}}>
                <label>
                    <span style={{color: "red"}}>*</span>
                    <span>Nome utente</span>
                </label>
                <Input style={{borderRadius: "8px", height: "38px"}}
                       value={this.state.input}
                       onChange={event => this.handleTextChange(event.target.value)}
                       status={this.state.validateStatus}/>
                {this.state.validateStatus && <span style={{color: "red"}}>{this.props.p.t("inspection.alert_username")}</span>}
            </div>
        );
    }
};

UserIdentityInput = translate(UserIdentityInput);