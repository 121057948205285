import {
  START_TWILIO_CALL,
  STARTED_TWILIO_CALL,
  END_TWILIO_CALL,
  START_TWILIO_RECORDING,
  STOP_TWILIO_RECORDING,

  START_RECORDING,
  SAVE_STREAMID_TO_RECORD,
  TAKE_SCREENSHOT,
  LOAD_ALL_SCREENSHOTS,
  LOAD_ALL_VIDEOS,
  CLEAR_VIDEOS_AND_SCREENSHOTS,
  OPEN_INSPECTION,
  CLOSE_INSPECTION, ERROR_ENTER_CALL,
} from "../actions";

const INITIAL_STATE = {
  customerUuid: null,
  activateTwilioCall: false,
  twilioCallActive: false,
  twilioRecording: false,
  inspectionOpen: false,
  twilioToken: null,
  videoRoom: null,
  videoMuted: false,
  recordingRoom: null,
  recordingIdentity: null,

  activateAgoraCall: false,
  channelName: null,
  resourceIdScreenshot: null,
  resourceIdRecording: null,
  sidScreenshot: null,
  sidRecording: null,
  message: null,
  streamId: null,
  videoInspections: [],
  screenshots: [],
  isFetchingTwilio: false,
  errorMessageStart: null,
  errorStartingCall: false,
};

export default function InspectionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_INSPECTION:
      return {
        ...state,
        inspectionOpen: true,
      };
    case CLOSE_INSPECTION:
      return {
        ...state,
        inspectionOpen: false,
      };
    case START_TWILIO_CALL: 
      return {
        ...state,
        activateTwilioCall: true,
        isFetchingTwilio: true
      };
    case STARTED_TWILIO_CALL: 
      //console.log('STARTED_TWILIO_CALL', action);
      return {
        ...state,
        twilioToken: action.token,
        videoRoom: action.videoRoom,
        twilioCallActive: true,
        isFetchingTwilio: false
      };
    case END_TWILIO_CALL:
      return {
        ...state,
        videoRoom: null,
        activateTwilioCall: false,
        twilioCallActive: false,
      };
    case START_TWILIO_RECORDING:
      return {
        ...state,
        twilioRecording: true,
        recordingRoom: action.recordingRoom,
        recordingIdentity: action.recordingIdentity,
      };
    case STOP_TWILIO_RECORDING:
      return {
        ...state,
        twilioRecording: false,
        recordingRoom: null,
        recordingIdentity: null,
      };

    case START_RECORDING:
      return {
        ...state,
        resourceIdRecording: action.resourceIdRecording,
        sidRecording: action.sidRecording,
        message: action.message,
      };
    case SAVE_STREAMID_TO_RECORD:
      return {
        ...state,
        streamId: action.payload,
      };
    case TAKE_SCREENSHOT:
      return {
        ...state,
        resourceIdScreenshot: action.resourceIdScreenshot,
        sidScreenshot: action.sidScreenshot,
        message: action.message,
      };
    case LOAD_ALL_SCREENSHOTS:
      return {
        ...state,
        screenshots: [...action.payload],
      };
    case LOAD_ALL_VIDEOS:
      return {
        ...state,
        videoInspections: [...action.payload],
      };
    case CLEAR_VIDEOS_AND_SCREENSHOTS:
      return {
        ...state,
        videoInspections: [],
        screenshots: [],
      };
    case ERROR_ENTER_CALL:
      return {
        ...state,
        errorMessageStart: action.message,
        errorStartingCall: true,
        isFetchingTwilio: false,
      }
    default:
      return state;
  }
}
