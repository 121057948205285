import React, {Component} from "react";
import {MessageOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class ChatButton extends Component {
    render() {
        return (
            <span className="ag-btn inspector-toolbar-icon inspector-toolbar-btn"
                  title={this.props.p.t("inspection.chat")}
                  onClick={() => this.props.setChatOpen()}
                  style={{position: "relative", transition: "0.3s"}}>
                <MessageOutlined className="inspector-toolbar-icon inspector-toolbar-"/>
            </span>
        );
    }
}

ChatButton = translate(ChatButton);

export {ChatButton};