import React from "react";
import translate from "redux-polyglot/translate";
import Select from "react-select";

export default class InputDeviceSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.handleSelection = this.handleSelection.bind(this);
        this.pickPlaceholder = this.pickPlaceholder.bind(this);
    }

    handleSelection(selectedOption) {
        switch (this.props.type) {
            case "audio":
                this.props.onDeviceSelected("audioinput", selectedOption.value);
                break;
            case "video":
                this.props.onDeviceSelected("videoinput", selectedOption.value);
                break;
            default:
                break;
        }
    }

    pickPlaceholder() {
        switch (this.props.type) {
            case "audio":
                if (this.props.devices.length === 0 && !this.props.noDevices) {
                    return "sto rilevando le fonti audio...";
                } else if (this.props.noDevices) {
                    return "Nessuna fonte audio rilevata";
                } else {
                    return this.props.p.t("inspection.choose_audio_device");
                }
            case "video":
                if (this.props.devices.length === 0 && !this.props.noDevices) {
                    return "sto rilevando le fonti video...";
                } else if (this.props.noDevices) {
                    return "Nessuna fonte video rilevata";
                } else {
                    return this.props.p.t("inspection.choose_video_device");
                }
            default:
                return "";
        }
    }

    render() {
        return (
            <div style={{margin: "1rem 0"}}>
                <label>
                    {this.props.required && <span style={{color: "red"}}>*</span>}
                    {this.props.type === "audio" && <span>Audio input</span>}
                    {this.props.type === "video" && <span>Video input</span>}
                </label>
                <Select isDisabled={this.props.noDevices}
                        isLoading={this.props.devices.length === 0 && !this.props.noDevice}
                        options={this.props.devices?.filter(device => device.deviceId !== '')?.map(device => ({label: device.label, value: device.deviceId}))}
                        isSearchable={false}
                        onChange={(event) => this.handleSelection(event)}
                        placeholder={this.pickPlaceholder()}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "8px"
                            }),
                        }}
                        value={this.props.devices?.filter(device => device.deviceId !== '')?.map(device => ({
                            label: device.label,
                            value: device.deviceId
                        }))?.find(device => device.value === this.props.currentDeviceId)}/>
            </div>
        );
    }
};

InputDeviceSelect = translate(InputDeviceSelect);