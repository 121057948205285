import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import thunk from "redux-thunk";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import reducers from "./combineReducers";
import {createPolyglotMiddleware} from "redux-polyglot";
import intl from "./locale/translations.json";
import {VideocallInspectionContainer} from "./inspection";


const polyglotMiddleware = createPolyglotMiddleware(
    "SET_LANGUAGE",
    (action) => action.payload.locale,
    (locale) => {
        const loc = locale;
        return new Promise((resolve) => {
            resolve(intl[loc]);
        });
    }
);

const createStoreWithMiddleware = applyMiddleware(
    thunk,
    polyglotMiddleware
)(createStore);

let store = createStoreWithMiddleware(reducers);
store.dispatch({type: "SET_LANGUAGE", payload: {locale: "it"}});

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <VideocallInspectionContainer />
      </div>
    </Provider>
  );
}

export default App;
