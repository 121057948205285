import React, {Component} from "react";
import {TeamOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class ShowRemoteButton extends Component {
    render() {
        return (
            <span title={this.props.p.t("inspection.thumbnail_visibility")}
                  style={{position: "relative"}}
                  onClick={() => this.props.setShowThumbnails(!this.props.showThumbnails)}
                  className={(this.props.showThumbnails ? "" : "barred") + " remotesControlBtn inspector-toolbar-icon inspector-toolbar-btn"}>
                <TeamOutlined className="inspector-toolbar-icon inspector-toolbar-remotes"/>
            </span>
        );
    }
}

ShowRemoteButton = translate(ShowRemoteButton);

export {ShowRemoteButton}