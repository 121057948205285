import React, {Component} from "react";
import {AudioOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class AudioControlButton extends Component {

    render() {
        return (
            <span title={this.props.p.t("inspection.en_dis_mic")}
                  style={{position: "relative"}}
                  onClick={() => this.props.setIsMuted(!this.props.isMuted)}
                  className={(this.props.isMuted ? "barred " : "") + " audioControlBtn inspector-toolbar-icon inspector-toolbar-btn"}>
                <AudioOutlined className="inspector-toolbar-icon inspector-toolbar-audio"/>
            </span>
        );
    }
}

AudioControlButton = translate(AudioControlButton);

export {AudioControlButton};