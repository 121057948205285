import React, {Component} from "react";
import {PhoneOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class CloseCallButton extends Component {
    render() {
        return (
            <span title={this.props.p.t("inspection.exit")}
                  onClick={() => this.props.onClose()}
                  className={
                      this.props.readyState
                          ? "exitBtn inspector-toolbar-icon inspector-toolbar-btn"
                          : "exitBtn inspector-toolbar-icon disabled inspector-toolbar-btn"
                  }>
                <PhoneOutlined className="inspector-toolbar-icon inspector-toolbar-exit-down"
                                style={{transform: "rotate(-135deg)"}}/>
        </span>
        );
    }
}

CloseCallButton = translate(CloseCallButton);

export {CloseCallButton};