import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import translate from "redux-polyglot/translate";
import debounce from "lodash/debounce";
import {AudioOutlined, VideoCameraOutlined} from "@ant-design/icons";

class Participant extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audiotrack: null,
            videotrack: null,
            datatrack: null,
            muted: false,
            hidden: false,
            currentvideodevice: -1,
            videodevices: 0,
        };

        this.videocontainer = React.createRef();

        this.forceRefresh = this.forceRefresh.bind(this);
        this.handleRemoteMsg = this.handleRemoteMsg.bind(this);
        this.handleTrackPublished = this.handleTrackPublished.bind(this);
        this.handleSelectThisParticipant = this.handleSelectThisParticipant.bind(this);
        this.attachAllTracks = debounce(this.attachAllTracks.bind(this), 300);
        this.attachTrack = this.attachTrack.bind(this);
    }

    componentWillUnmount() {
        this.videocontainer.current.innerHTML = "";
    }

    forceRefresh() {
        this.forceUpdate();
    }

    attachTrack(track) {
        if (track.kind === "audio" && this.state.audiotrack !== track) {
            if (this.state.audiotrack !== null && this.videocontainer.current !== null) {
                let el = this.state.audiotrack.detach(this.videocontainer.current.querySelector("audio"));
                if (!el.remove) el.forEach(el => {
                    if (el.remove) el.remove();
                });
                else el.remove();
            }
            if (this.videocontainer.current)
                this.videocontainer.current.appendChild(track.attach());
            track.on("enabled", t => {
                if (t === this.state.audiotrack) this.setState({muted: false});
            });
            track.on("disabled", t => {
                if (t === this.state.audiotrack) this.setState({muted: true});
            });
            return true;
        } else if (track.kind === "video" && this.state.videotrack !== track) {
            if (this.state.videotrack !== null && this.videocontainer.current !== null) {
                const el = this.state.audiotrack.detach(this.videocontainer.current.querySelector("video"));
                if (!el.remove) el.forEach(el => {
                    if (el.remove) el.remove();
                });
                else el.remove();
            }
            if (this.videocontainer.current)
                this.videocontainer.current.appendChild(track.attach());
            track.on("enabled", t => {
                if (t === this.state.videotrack) this.setState({hidden: false});
            });
            track.on("disabled", t => {
                if (t === this.state.videotrack) this.setState({hidden: true});
            });
            return true;
        } else if (track.kind === "data" && this.state.datatrack !== track) {
            if (this.props.onRemoteMessage) {
                track.on("message", data => {
                    let remoteMsg = JSON.parse(data);
                    this.handleRemoteMsg(remoteMsg);
                });
                return true;
            }
        }
        return false;
    }

    attachAllTracks() {
        let _newaudiotrack = null;
        let _newvideotrack = null;
        let _newdatatrack = null;
        this.props.participant.tracks.forEach(publication => {
            if (publication.kind === "audio" && publication.track !== this.state.audiotrack) {
                if (publication.track && this.attachTrack(publication.track))
                    _newaudiotrack = publication.track;
            } else if (publication.kind === "video" && publication.track !== this.state.videotrack) {
                if (publication.track && this.attachTrack(publication.track))
                    _newvideotrack = publication.track;
            } else if (publication.kind === "data" && publication.track !== this.state.datatrack) {
                if (publication.track && this.attachTrack(publication.track)) {
                    _newdatatrack = publication.track;
                }
            }
        });
        if (_newaudiotrack !== null || _newvideotrack !== null || _newdatatrack !== null) {
            let diffState = {};
            if (_newvideotrack !== null) {
                diffState = {...diffState, videotrack: _newvideotrack, hidden: !_newvideotrack.isEnabled};
            }
            if (_newaudiotrack !== null) {
                diffState = {...diffState, audiotrack: _newaudiotrack, muted: !_newaudiotrack.isEnabled};
            }
            if (_newdatatrack !== null) {
                diffState = {...diffState, datatrack: _newdatatrack};
                this.props.onDataTrackReady(this.props.participant);
            }
            this.setState(diffState);
        }
    }

    handleRemoteMsg(msg) {
        if (msg.type === "listvideodevices" && msg.participant && this.props.participant.identity === msg.participant) {
            this.setState({
                videodevices: msg.videodevices,
                currentvideodevice: msg.currentvideodevice,
            });
        }
        this.props.onRemoteMessage(msg);
    }

    handleTrackPublished(publication) {
        if (publication.track && this.attachTrack(publication.track)) {
            if (publication.kind === "audio")
                this.setState({audiotrack: publication.track});
            else if (publication.kind === "video")
                this.setState({videotrack: publication.track});
            else if (publication.kind === "data") {
                if (this.props.onDataTrackReady) {
                    this.props.onDataTrackReady();
                }
                this.setState({datatrack: publication.track});
            }
        }
    }

    handleSelectThisParticipant(ev) {
        if (!this.props.selectParticipant) return;
        this.props.selectParticipant(this.props.participant);
    }

    componentDidMount() {
        if (this.props.participant) {
            this.attachAllTracks();
            this.props.participant.on("trackSubscribed", this.forceRefresh);
            this.props.participant.on("trackUnsubscribed", this.forceRefresh);
            this.props.participant.on("trackPublished", this.handleTrackPublished);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.participant !== this.props.participant && prevProps.participant !== null) {
            this.videocontainer.current.innerHTML = "";
        }
        if (this.props.participant) {
            this.attachAllTracks();
            if (prevProps.participant !== this.props.participant) {
                this.props.participant.on("trackSubscribed", this.forceRefresh);
                this.props.participant.on("trackUnsubscribed", this.forceRefresh);
                this.props.participant.on("trackPublished", this.handleTrackPublished);
            }
        }
    }

    render() {
        return (
            <div className={`video-room-participant ${this.props.className}`}
                 id={this.props.isFocused === true ? "focused-video" : ""}>
                {this.props.showstatus &&
                    <div style={{
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                        color: "red",
                        zIndex: "10",
                        fontSize: this.props.isFocused ? "1.5rem" : "1rem",
                        display: "flex"
                    }}>
                        {this.state.muted && <span className={this.props.isFocused === true ? "device-status-barred-focused": "device-status-barred"} style={{color: "red"}}><AudioOutlined/></span>}
                        {this.state.hidden && <span className={this.props.isFocused === true ? "device-status-barred-focused": "device-status-barred"} style={{color: "red"}}><VideoCameraOutlined/></span>}
                    </div>
                }
                {this.props.participant && this.props.isFocused && (
                    <div className={"identity-name-big"}>
                        <span>{this.props.participant.identity}</span>
                    </div>
                )}
                <div className="participant-video-container"
                     data-identity={this.props.participant ? this.props.participant.identity : ""}
                     style={{width: "100%"}}
                     ref={this.videocontainer}
                     onClick={this.handleSelectThisParticipant}>
                    {this.props.participant && !this.props.isFocused && (
                        <div className={"identity-name-small"}>
                            <span>{this.props.participant.identity}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}


Participant = translate(Participant);

function mapStateToProps(state) {
    return {
        room: state.inspection.videoRoom,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

Participant = connect(
    mapStateToProps,
    mapDispatchToProps
)(Participant);

export {Participant};