import React, {Component} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {Popover} from "antd";
import translate from "redux-polyglot/translate";
import InputDeviceSelect from "./InputDeviceSelect";

class SwitchInputButton extends Component {
    render() {
        return (
            <Popover title={this.props.p.t("inspection.switch_camera")}
                     trigger={"click"}
                     content={
                         <div>
                             <InputDeviceSelect type={"video"} required={false} devices={this.props.videoDevices} currentDeviceId={this.props.currentVideoDevice} onDeviceSelected={this.props.ondeviceselected} noDevices={this.props.noVideoSources}/>
                             <InputDeviceSelect type={"audio"} required={false} devices={this.props.audioDevices} currentDeviceId={this.props.currentAudioDevice} onDeviceSelected={this.props.ondeviceselected} noDevices={this.props.noAudioSources}/>
                         </div>
                     }>
                <span className="inspector-toolbar-icon inspector-toolbar-btn"
                      title={this.props.p.t("inspection.switch_camera")}
                      style={{position: "relative", transition: "0.3s"}}>
                  <SyncOutlined className="inspector-toolbar-icon inspector-toolbar-camera"/>
                </span>
            </Popover>
        );
    }
}

SwitchInputButton = translate(SwitchInputButton);

export {SwitchInputButton};