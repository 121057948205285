/**
 * Combines app modules reducers to access them from the app state
 */
 import { combineReducers } from "redux"; 
 import { reducers as InspectionReducers } from "./inspection";
 import { polyglotReducer } from "redux-polyglot";
 
 const rootReducer = combineReducers(Object.assign({}, { polyglot: polyglotReducer }, InspectionReducers));
 
 export default rootReducer;
 