/**
 * Exports an object containing all reducers for this module
 */
import InspectionReducer from "./inspection_reducer";

const reducers = {
  inspection: InspectionReducer,
};

export { reducers };
