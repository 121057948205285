import React, {Component} from "react";
import {ArrowsAltOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class FullscreenButton extends Component {

    extendScreen() {
        let el = document.getElementById(this.props.videoId);
        let video = null;
        if (el) {
            let videos = el.getElementsByTagName("video");
            if (videos && videos.length > 0) {
                video = videos[0];
            }
        }
        if (video) {
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullScreen) {
                video.webkitRequestFullScreen();
            } else if (video.webkitEnterFullscreen) {
                video.play();
                video.webkitEnterFullscreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            }
        }

    }
    render() {
        return (
            <span className="inspector-toolbar-icon inspector-toolbar-btn"
                  id="inspector-extend-button"
                  title={this.props.p.t("inspection.extend")}
                  style={{position: "relative", transition: "0.3s"}}
                  onClick={()=>this.extendScreen()}>
                <ArrowsAltOutlined className="inspector-toolbar-icon"/>
            </span>
        );
    }
}

FullscreenButton = translate(FullscreenButton);
export {FullscreenButton};