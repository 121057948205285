import React from "react";
import {Comment, List} from "antd";
import translate from "redux-polyglot/translate";
import {UserOutlined} from "@ant-design/icons";

/**
 * Returns a list of messages sent and received by the participant.
 * @return {Array.<Comment>}
 */
export default class ChatMessagesList extends React.Component {

    render() {
        return (
            <List header={null}
                  itemLayout={"horizontal"}
                  locale={{emptyText: this.props.noParticipants ? this.props.p.t("inspection.no_one_joined"): this.props.p.t("inspection.chat_empty")}}
                  dataSource={this.props.chat}
                  renderItem={(message, index) => <li key={index}>
                      <Comment datetime={message.datetime}
                               content={<p>{message.text}</p>}
                               avatar={<UserOutlined/>}
                               author={message.from}
                               className={this.props.identity === message.from ? "my-message" : "received-message"}/>
                  </li>
                  }

            />
        );
    }
};
ChatMessagesList = translate(ChatMessagesList);
