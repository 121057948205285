import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import translate from "redux-polyglot/translate";
import {Participant} from "./Participant";
import {endTwilioCall} from "../../actions";
import {createLocalVideoTrack, createLocalAudioTrack} from "twilio-video";
import {Badge, message} from "antd";
import {AudioControlButton} from "./RoomControlButtons/AudioControlButton";
import {VideoControlButton} from "./RoomControlButtons/VideoControlButton";
import {ShowRemoteButton} from "./RoomControlButtons/ShowRemoteButton";
import {SwitchInputButton} from "./RoomControlButtons/SwitchInputButton";
import {ChatButton} from "./RoomControlButtons/ChatButton";
import {FullscreenButton} from "./RoomControlButtons/FullscreenButton";
import {CloseCallButton} from "./RoomControlButtons/CloseCallButton";
import VideoCallChat from "./VideoCallChat";

const TALK_VOLUME = 0.04;

class VideoRoom extends Component {
    constructor(props) {
        super(props);

        this.sendLocationTimer = null;

        this.state = {
            initialized: false,
            muted: false,
            hidden: false,
            focusedParticipant: null,
            listVideodevices: false,
            listAudiodevices: false,
            startTheRecording: false,
            showThumbnails: true,
            showChat: false,
            chat: [],
            unredMessages: 0,
        };

        this.localVideo = React.createRef();
        this.mainVideo = React.createRef();
        this.remoteVideos = React.createRef();

        this.analyser = null;

        this.sendMessage = this.sendMessage.bind(this);
        this.sendCurrentLocation = this.sendCurrentLocation.bind(this);
        this.onDataTrackReady = this.onDataTrackReady.bind(this);
        this.switchCamera = this.switchCamera.bind(this);
        this.highlightParticipant = this.highlightParticipant.bind(this);
        this.remoteMessageReceived = this.remoteMessageReceived.bind(this);

        this.newChatMessage = this.newChatMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        this.setState({focusedParticipant: this.props.room.participants.size === 0 ? this.props.room.localParticipant : Array.from(this.props.room.participants, ([k, v])=>v)[0]});

        this.props.room.on("participantConnected", participant => {
            message.info(`${participant.identity} ${this.props.p.t("inspection.has_joined")}`);
            const participants = Array.from(this.props.room.participants, (([k, v]) => v));
            if (participants.length === 1) {
                this.setState({focusedParticipant: participants[0]});
            } else {
                this.forceUpdate();
            }
        });
        this.props.room.on("participantDisconnected", participant => {
            message.info(`${participant.identity} ${this.props.p.t("inspection.has_left")}`);
            if (participant.identity === this.state.focusedParticipant.identity) {
                const participants = Array.from(this.props.room.participants, (([k, v]) => v));
                this.setState({focusedParticipant: participants.length > 0 ? participants[0] : this.props.room.localParticipant});
            } else {
                this.forceUpdate();
            }
        });

        this.getAnalyser().then((analyser) => {
            this.analyser = analyser;
        });

        if (this.props.geolocator) {
            setTimeout(() => {
                this.sendCurrentLocation();
            }, 2000);
            this.sendLocationTimer = setInterval(() => {
                this.sendCurrentLocation();
            }, 30000);
        }

        // Every second checks if the participant is muted and is talking
        this.interval = setInterval(() => {
            const volume = this.getVolume();
            if (this.state.muted && volume > TALK_VOLUME) {
                message.warning(this.props.p.t("inspection.are_you_talking"));
            }
        }, 1000);
    }

    /**
     * Returns a promise that if successful returns an AnalyserNode.
     * @return {Promise<AnalyserNode>}
     */
    getAnalyser() {
        return navigator.mediaDevices
            .getUserMedia({audio: true, video: false})
            .then((stream) => {
                const audioContext = new AudioContext();
                const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
                const analyserNode = audioContext.createAnalyser();
                mediaStreamAudioSourceNode.connect(analyserNode);
                return analyserNode;
            });
    }

    /**
     * Returns a number representing the noise level detected by the microphone.
     * @return {number}
     */
    getVolume() {
        if (this.analyser) {
            const pcmData = new Float32Array(this.analyser.fftSize);
            this.analyser.getFloatTimeDomainData(pcmData);
            return Math.sqrt(pcmData.reduce((accumulator, currentValue) => accumulator += Math.pow(currentValue, 2), 0.0) / pcmData.length);
        }
        return 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.currentAudioDevice !== this.props.currentAudioDevice) {
            Array.from(this.props.room.localParticipant.audioTracks.values()).forEach(t => {
                if (t.track.stop) t.track.stop();
            });
            createLocalAudioTrack({deviceId: this.props.currentAudioDevice})
                .then((localAudioTrack) => {
                    const tracks = Array.from(this.props.room.localParticipant.audioTracks.values()).map(t => t.track);
                    this.props.room.localParticipant.unpublishTracks(tracks);
                    this.props.room.localParticipant.publishTrack(localAudioTrack);
                    if (this.state.muted) localAudioTrack.disable();
                });
        }

        if (prevProps.currentVideoDevice !== this.props.currentVideoDevice) {
            Array.from(this.props.room.localParticipant.videoTracks.values()).forEach(t => {
                if (t.track.stop) t.track.stop();
            });
            createLocalVideoTrack({deviceId: this.props.currentVideoDevice})
                .then((localVideoTrack) => {
                    const tracks = Array.from(this.props.room.localParticipant.videoTracks.values()).map(t => t.track);
                    this.props.room.localParticipant.unpublishTracks(tracks);
                    this.props.room.localParticipant.publishTrack(localVideoTrack);
                    if (this.state.hidden) localVideoTrack.disable();
                });
        }

        if (prevState.muted !== this.state.muted) {
            if (this.state.muted) {
                this.props.room.localParticipant.audioTracks.forEach(publication => {
                    publication.track.disable();
                });
            } else {
                this.props.room.localParticipant.audioTracks.forEach(publication => {
                    publication.track.enable();
                });
            }
        }

        if (prevState.hidden !== this.state.hidden) {
            if (this.state.hidden) {
                this.props.room.localParticipant.videoTracks.forEach(publication => {
                    publication.track.disable();
                });
            } else {
                this.props.room.localParticipant.videoTracks.forEach(publication => {
                    publication.track.enable();
                });
            }
        }

        message.config({
            maxCount: 1
        });
    }

    componentWillUnmount() {
        if (this.sendLocationTimer)
            clearInterval(this.sendLocationTimer);

        Array.from(this.props.room.localParticipant.tracks.values()).forEach(t => {
            if (t.track.stop) t.track.stop();
        });
        this.props.endTwilioCall(this.props.room);

        clearInterval(this.interval);
    }

    /**
     * Handles the sending of a new chat message by the local participant.
     * @param to
     * @param text
     */
    newChatMessage(to, text) {
        const today = new Date();
        this.sendMessage({
            type: "chat",
            from: this.props.room.localParticipant.identity,
            to,
            datetime: [today.getHours(), today.getMinutes(), today.getSeconds()]
                .map((d) => String(d).padStart(2, "0"))
                .join(":"),
            text
        });
        this.setState({
            chat: [...this.state.chat, {
                from: this.props.room.localParticipant.identity,
                to,
                datetime: [today.getHours(), today.getMinutes(), today.getSeconds()]
                    .map((d) => String(d).padStart(2, "0"))
                    .join(":"),
                text
            }]
        });
    }

    sendMessage(msg) {
        let _localDataTrack = null;
        Array.from(this.props.room.localParticipant.dataTracks.values()).forEach(publication => {
            _localDataTrack = publication.track;
        });

        if (_localDataTrack) {
            _localDataTrack.send(JSON.stringify(msg));
        }
    }

    sendCurrentLocation() {
        if (this.props.geolocator) {
            this.props.geolocator.getCurrentPosition((pos) => {
                let lat = pos.coords.latitude;
                let lng = pos.coords.longitude;
                let msg = {
                    type: "geolocation",
                    participant: this.props.room.localParticipant.identity,
                    lat: lat,
                    lng: lng
                };
                this.sendMessage(msg);
            }, (err) => {
                console.log(err);
            }, {enableHighAccuracy: true, timeout: 5000, maximumAge: 2000});
        } else {
            console.log("GEOLOCATOR NOT AVAILABLE");
        }
    }

    onDataTrackReady(participant) {
        let _currDevice = null;
        if (this.props.videoDevices.length) {
            for (let i = 0; i < this.props.videoDevices.length; i++) {
                if (this.props.videoDevices[i].deviceId === this.props.currentVideoDevice) {
                    _currDevice = i;
                    break;
                }
            }
        }
        let msg = {
            type: "listvideodevices",
            participant: this.props.room.localParticipant.identity,
            videodevices: this.props.videoDevices.length,
            currentvideodevice: _currDevice,
        };
        this.sendMessage(msg);

        this.sendCurrentLocation();
    }

    highlightParticipant(participant) {
        this.setState({focusedParticipant: participant});
    }

    switchCamera(participant) {
        let msg = {
            to: participant.identity,
            type: "switchcam",
        };
        this.sendMessage(msg);
    }

    remoteMessageReceived(msg) {
        if (msg.type === "switchcam" && msg.to && msg.to === this.props.room.localParticipant.identity) {
            for (let i = 0; i < this.props.videoDevices.length; i++) {
                if (this.props.videoDevices[i].deviceId === this.props.currentVideoDevice) {
                    this.props.ondeviceselected("videoinput", this.props.videoDevices[(i + 1) % this.props.videoDevices.length].deviceId);
                    return;
                }
            }
        } else if (msg.type === "chat") {
            this.setState({
                chat: [...this.state.chat, {
                    from: msg.from,
                    to: msg.to,
                    datetime: msg.datetime,
                    text: msg.text
                }],
                unredMessages: this.state.unredMessages + (this.state.showChat === false ? 1 : 0),
            });
            message.info(this.props.p.t("inspection.new_message") + " " + msg.from, 2);
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.showChat && this.props.room.localParticipant && (
                    <VideoCallChat participant={this.props.room.localParticipant}
                                   participants={Array.from(this.props.room.participants, ([k, v]) => v)}
                                   room={this.props.room}
                                   chat={this.state.chat}
                                   onNewMessage={this.newChatMessage}
                                   setChat={newChat => this.setState(newChat)}
                                   onClose={() => this.setState({showChat: false})}/>
                )}
                <div
                    className={`video-room-container`}>
                    {this.state.showThumbnails && this.props.room.localParticipant.identity !== this.state.focusedParticipant?.identity &&
                        <Participant className="local-video"
                                     selectParticipant={this.highlightParticipant}
                                     focused={this.state.focusedParticipant}
                                     participant={this.props.room.localParticipant}
                                     room={this.props.room}
                                     showstatus={true}
                                     onDataTrackReady={this.onDataTrackReady}/>
                    }
                    <Participant className="focused-video"
                                 participant={this.state.focusedParticipant}
                                 onDataTrackReady={this.onDataTrackReady}
                                 onRemoteMessage={(msg) => this.remoteMessageReceived(msg)}
                                 showstatus={true}
                                 isFocused={true}/>
                    {this.state.showThumbnails &&
                        <div className="remote-videos">
                            {Array.from(this.props.room.participants, ([k, v]) => v).filter(participant => participant.identity !== this.state.focusedParticipant?.identity).map(participant =>
                                <Participant key={participant.identity}
                                             showstatus={true}
                                             onRemoteMessage={(msg) => this.remoteMessageReceived(msg)}
                                             onDataTrackReady={this.onDataTrackReady}
                                             selectParticipant={this.highlightParticipant}
                                             switchCamera={this.switchCamera}
                                             className="remote-video"
                                             focused={this.state.focusedParticipant}
                                             room={this.props.room}
                                             participant={participant}/>)}
                        </div>
                    }
                    <div className={"room-controls-container"}>
                        {<AudioControlButton isMuted={this.state.muted}
                                             setIsMuted={isMuted => this.setState({muted: isMuted})}/>}
                        {<VideoControlButton isVideoActive={this.state.hidden}
                                             setIsVideoActive={isVideoActive => this.setState({hidden: isVideoActive})}/>}
                        {<ShowRemoteButton showThumbnails={this.state.showThumbnails}
                                           setShowThumbnails={thumbnailsVisibility => this.setState({showThumbnails: thumbnailsVisibility})}/>}
                        {<SwitchInputButton videoDevices={this.props.videoDevices}
                                            currentVideoDevice={this.props.currentVideoDevice}
                                            audioDevices={this.props.audioDevices}
                                            noAudioSources={this.props.noAudioSources}
                                            noVideoSources={this.props.noVideoSources}
                                            currentAudioDevice={this.props.currentAudioDevice}
                                            ondeviceselected={this.props.ondeviceselected}/>}
                        {<Badge count={this.state.unredMessages}>
                            <ChatButton setChatOpen={() => this.setState({
                                showChat: !this.state.showChat,
                                unredMessages: this.state.showChat ? this.state.unredMessages : 0
                            })}/>
                        </Badge>}
                        {<FullscreenButton videoId={"focused-video"}/>}
                        {<CloseCallButton onClose={() => this.props.closeInspection(this.props.room)}
                                          readyState={this.state.readyState}/>}
                    </div>
                </div>
            </Fragment>
        );
    }
}

VideoRoom = translate(VideoRoom);

function mapStateToProps(state) {
    return {
        room: state.inspection.videoRoom,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        endTwilioCall,
    }, dispatch);
}

VideoRoom = connect(mapStateToProps, mapDispatchToProps)(VideoRoom);

export {VideoRoom};