import React, {Component} from "react";
import {VideoCameraOutlined} from "@ant-design/icons";
import translate from "redux-polyglot/translate";

class VideoControlButton extends Component {
    render() {
        return (
            <span title={this.props.p.t("inspection.en_dis_video")}
                  style={{position: "relative"}}
                  onClick={() => this.props.setIsVideoActive(!this.props.isVideoActive)}
                  className={(this.props.isVideoActive ? "barred " : "") + " videoControlBtn inspector-toolbar-icon inspector-toolbar-btn"}>
                <VideoCameraOutlined className="inspector-toolbar-icon inspector-toolbar-videocamera"/>
            </span>
        );
    }
}

VideoControlButton = translate(VideoControlButton);

export {VideoControlButton};