import React, {Component} from "react";
import Logo from "../../../images/full_logo.svg";
import translate from "redux-polyglot/translate";
import {connect} from "react-redux";
import UserIdentityInput from "./RoomControlButtons/UserIdentityInput";
import InputDeviceSelect from "./RoomControlButtons/InputDeviceSelect";
import {Button, notification} from "antd";

class JoinCall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            identity: "",
        };

    }

    render() {
        return (
            <div className="inspector-lobby">
                <div className="header">
                    <img src={Logo}
                         alt="RiskAPP Logo"
                         className="riskapp-logo"/>
                </div>
                <div className="inspector-lobby-message">
                    <p>{this.props.p.t("inspection.join_inspection_description")}</p>
                </div>
                <UserIdentityInput identity={this.state.identity}
                                   setIdentity={(newIdentity) => this.setState({identity: newIdentity})}/>
                <InputDeviceSelect type={"audio"}
                                   required={true}
                                   devices={this.props.audioDevices}
                                   currentDeviceId={this.props.currentAudioDevice}
                                   onDeviceSelected={this.props.ondeviceselected}
                                   noDevices={this.props.noAudioSources}/>
                <InputDeviceSelect type={"video"}
                                   required={false}
                                   devices={this.props.videoDevices}
                                   currentDeviceId={this.props.currentVideoDevice}
                                   onDeviceSelected={this.props.ondeviceselected}
                                   noDevices={this.props.noVideoSources}/>
                <section className={"enter-meeting-footer"} style={{margin: "2rem 0"}}>
                    <Button id="joinBtn"
                            disabled={!this.state.identity || !this.props.currentAudioDevice}
                            style={{borderRadius: "8px", height: "38px"}}
                            onClick={() => {
                                const params = new URLSearchParams(window.location.search);
                                const pwd = params.get("passcode");
                                const roomName = params.get("room_name");

                                if (!roomName || !pwd) {
                                    notification.error({
                                        message: "Impossibile unirsi all'ispezione",
                                        description: this.props.p.t("inspection.alert_parameters")
                                    });
                                } else {
                                    this.props.onstartcall(this.state.identity, roomName, pwd);
                                }
                            }}
                            type="primary">{this.props.p.t("inspection.join")}</Button>
                </section>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

JoinCall = translate(JoinCall);

JoinCall = connect(mapStateToProps)(JoinCall);

export {JoinCall};

/*JoinCall = connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinCall);*/